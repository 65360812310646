<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
        <path d="M1.26004 6.96875L16.3479 6.96875" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" />
        <path d="M10.1339 1.01367L16.3259 6.46957C16.5477 6.66498 16.5525 7.00909 16.3362 7.21057L10.1366 12.9858"
            stroke="currentColor" stroke-width="1.8" stroke-linecap="round"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
